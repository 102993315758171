export function dailySeed() {
    var date = new Date();

    var day = date.getUTCDate();
    var month = date.getUTCMonth();
    var year = date.getUTCFullYear();

    /* I stg if this ends up repeating daily seeds again I will be LIVID */
    return (day * 1000000) + (month * 100000) + (year * 100) + day + month + year;
}
