import { tilesToWord } from '../utilities/converters.js';
import './Submission.scss';


/* A submission entry in the submission list.
   Not to be confused with the CurrentSubmission. */
function Submission(props) {
    var word = tilesToWord(props.board, props.submission.tiles);

    return (
        <button class="submission" onClick={() => props.copyToClipboard()}>
            <div class="submission-word">
                {word.slice(0, 20) + (word.length > 20 ? "..." : "")}
            </div>
            <div class="submission-score">
                {props.submission.score}
            </div>
        </button>
    );
}

export default Submission;
