/* A seedable pseudo-random number generator. adapted from: 
https://stackoverflow.com/questions/521295/seeding-the-random-number-generator-in-javascript */
export function mulberry32(a) {
    return function() {
        a = (a + 0x6D2B79F5) % 4294967296;
        var t = (a += 0x6D2B79F5);
        t = Math.imul(t ^ (t >>> 15), t | 1); 
        t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
        return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    }
}
