import Submission from './Submission';
import { tilesToWord } from '../utilities/converters.js';
import { weightedScore } from '../utilities/wordScore.js';
import './SubmissionList.scss';

function copyToClipboard(board, submissionList, isDaily, submission) {
    var squares = ["\u{2b1c}", "\u{1f7e5}", "\u{1f7e7}", "\u{1f7eb}", "\u{1f7e9}", "\u{1f7e6}", "\u{1f7ea}"];
    var clip = "";

    if (isDaily) {
        var timeElapsed = (new Date() - new Date(Date.UTC(2022, 6, 13, 0, 0, 0, 0)));
        clip = clip.concat("Puzzle " + Math.floor(timeElapsed / 86400000));
    }
    else {
        clip = clip.concat("Practice Score");
    }
    clip = clip.concat(" -\n");

    if (submission === undefined) {
        clip = clip.concat("Top-7 Weighted Score: ||" + weightedScore(submissionList) + "||\n\n");
        for (var w = 0; w < Math.min(7, submissionList.length); ++w) {
            clip = clip.concat(squares[w] + " ||" + tilesToWord(board, submissionList[w].tiles) + "|| (||" + submissionList[w].score + "||)\n");
        }
    }
    else {
        clip = clip.concat(submission.score === submissionList[0].score ? "Best" : "Highlighted");
        clip = clip.concat(" Word: ||" + tilesToWord(board, submission.tiles) + "|| (Score: ||" + submission.score + "||)");
        for (var i = 0; i < board.length; ++i) {
            if (i % 4 === 0) {
                clip = clip.concat("\n ");
            }
            clip = clip.concat(submission.tiles.includes(i) ? "\u{1f7e9}" : "\u{2b1b}");
        }
    }

    clip = clip.concat("\n\nTry to Beat This " + (submission === undefined ? "Score" : "Word") + "!\n<https://letter-getter.foxsylv.dev>");

    alert("Copy and Paste to Share!\n\n" + clip);
    navigator.clipboard.writeText(clip);

}

function SubmissionList(props) {
    var submissions = props.submissionList.map((submission) =>
        <Submission key={tilesToWord(props.board, submission.tiles)}
                    board={props.board}
                    submission={submission}
                    copyToClipboard={() => copyToClipboard(props.board, props.submissionList, props.isDaily, submission)}
        />
    );

    return (
        <div id="submission-list">
            <div id="submission-list-header">
                <h2 id="submission-list-label">
                    {props.submissionList.length > 0 ? "Submission List" : ""}
                </h2>
                <h3 id="weighted-score" onClick={()=>copyToClipboard(props.board, props.submissionList, props.isDaily)}>
                    {props.submissionList.length > 0 ? weightedScore(props.submissionList) : ""}
                </h3>
            </div>
            <div id="submission-list-words">
                {submissions}
            </div>
        </div>
    );
}

export default SubmissionList;
