//Euclidean Algorithm
function gcd(a, b) {
    if (b === 0) {
        return a;
    }
    return gcd(b, a % b);
}

export function smallestRPrime(num) {
    for (var i = 2;; ++i) {
        if (gcd(i, num) === 1) {
            console.log(i);
            return i;
        }
    }
}
