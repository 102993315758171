import { randomSeed } from './randomSeed.js';


function currentDay() {
    var today = new Date();
    return today.getUTCDate() + " " + today.getUTCMonth() + " " + today.getUTCFullYear();
}


export function getStoredSubmissionList() {
    var list = localStorage.getItem(currentDay());
    if (list === null) {
        return [];
    }
    return JSON.parse(list).submissionList;
}
export function getShuffleNum() {
    var num = localStorage.getItem(currentDay());
    if (num === null) {
        return 0;
    }
    return JSON.parse(num).shuffleNum;
}
export function setStoredSubmissionList(submissionList, shuffleNumArg) {
    var shuffleNum = shuffleNumArg === undefined ? getShuffleNum() : shuffleNumArg;
    localStorage.setItem(currentDay(), '{"submissionList":' + JSON.stringify(submissionList) + ',"shuffleNum":' + shuffleNum + '}');
}


export function getUserUUID() {
    var uuid = localStorage.getItem("uuid");
    if (uuid === null) {
        var newUUID = randomSeed();
        localStorage.setItem("uuid", newUUID);
        return newUUID;
    }
    return uuid;
}
