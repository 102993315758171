
export const tileGenerationData = [
    {value: "A", probability: 950, maxNum: 4},
    {value: "B", probability: 200, maxNum: 3},
    {value: "C", probability: 200, maxNum: 3},
    {value: "D", probability: 400, maxNum: 4},
    {value: "E", probability: 1300, maxNum: 4},
    {value: "F", probability: 250, maxNum: 3},
    {value: "G", probability: 250, maxNum: 3},
    {value: "H", probability: 250, maxNum: 3},
    {value: "I", probability: 750, maxNum: 4},
    {value: "J", probability: 75, maxNum: 2},
    {value: "K", probability: 100, maxNum: 2},
    {value: "L", probability: 450, maxNum: 4},
    {value: "M", probability: 200, maxNum: 3},
    {value: "N", probability: 550, maxNum: 4},
    {value: "O", probability: 650, maxNum: 4},
    {value: "P", probability: 250, maxNum: 3},
    {value: "Qu", probability: 100, maxNum: 2},
    {value: "R", probability: 750, maxNum: 4},
    {value: "S", probability: 600, maxNum: 4},
    {value: "T", probability: 700, maxNum: 4},
    {value: "U", probability: 400, maxNum: 3},
    {value: "V", probability: 200, maxNum: 3},
    {value: "W", probability: 150, maxNum: 3},
    {value: "X", probability: 125, maxNum: 2},
    {value: "Y", probability: 250, maxNum: 3},
    {value: "Z", probability: 125, maxNum: 2}
];
