export const sequencePoints = [
    {sequence: "a", value: 10},
    {sequence: "b", value: 13},
    {sequence: "c", value: 13},
    {sequence: "d", value: 12},
    {sequence: "e", value: 10},
    {sequence: "f", value: 14},
    {sequence: "g", value: 12},
    {sequence: "h", value: 13},
    {sequence: "i", value: 10},
    {sequence: "j", value: 19},
    {sequence: "k", value: 17},
    {sequence: "l", value: 11},
    {sequence: "m", value: 14},
    {sequence: "n", value: 11},
    {sequence: "o", value: 12},
    {sequence: "p", value: 14},
    {sequence: "q", value: 16},
    {sequence: "r", value: 14},
    {sequence: "s", value: 12},
    {sequence: "t", value: 11},
    {sequence: "u", value: 12},
    {sequence: "v", value: 15},
    {sequence: "w", value: 15},
    {sequence: "x", value: 20},
    {sequence: "y", value: 21},
    {sequence: "z", value: 20},
    {sequence: "aa", value: 8},
    {sequence: "bb", value: 2},
    {sequence: "cc", value: 4},
    {sequence: "dd", value: 1},
    {sequence: "ee", value: 1},
    {sequence: "ff", value: 2},
    {sequence: "gg", value: 2},
    {sequence: "hh", value: 12},
    {sequence: "ii", value: 10},
    {sequence: "jj", value: 13},
    {sequence: "kk", value: 14},
    {sequence: "ll", value: 1},
    {sequence: "mm", value: 2},
    {sequence: "nn", value: 1},
    {sequence: "oo", value: 1},
    {sequence: "pp", value: 2},
    {sequence: "qq", value: 25},
    {sequence: "rr", value: 1},
    {sequence: "ss", value: 1},
    {sequence: "tt", value: 1},
    {sequence: "uu", value: 7},
    {sequence: "vv", value: 8},
    {sequence: "ww", value: 11},
    {sequence: "xx", value: 22},
    {sequence: "yy", value: 17},
    {sequence: "zz", value: 5},
    {sequence: "ae", value: 3},
    {sequence: "ao", value: 3},
    {sequence: "ei", value: 1},
    {sequence: "eo", value: 2},
    {sequence: "eu", value: 2},
    {sequence: "ey", value: 1},
    {sequence: "io", value: 1},
    {sequence: "iu", value: 1},
    {sequence: "iy", value: 2},
    {sequence: "oe", value: 3}, 
    {sequence: "ua", value: 1},
    {sequence: "ue", value: 1},
    {sequence: "uo", value: 1},
    {sequence: "uy", value: 2},
    {sequence: "ya", value: 1},
    {sequence: "yu", value: 2},
    {sequence: "er", value: 3},
    {sequence: "ers", value: 2},
    {sequence: "ier", value: 1},
    {sequence: "est", value: 1},
    {sequence: "iest", value: 3},
    {sequence: "re", value: 2},
    {sequence: "de", value: 1},
    {sequence: "ed", value: 2},
    {sequence: "ing", value: 3},
    {sequence: "tion", value: 5},
    {sequence: "tions", value: 1},
    {sequence: "ation", value: 1},
    {sequence: "out", value: 3},
    {sequence: "auto", value: 7},
    {sequence: "over", value: 2},
    {sequence: "under", value: 1},
    {sequence: "way", value: 3},
    {sequence: "ium", value: 2},
    {sequence: "cyan", value: 1},
    {sequence: "dyne", value: 2},
    {sequence: "ous", value: 3},
    {sequence: "eous", value: 2},
    {sequence: "ious", value: 1},
    {sequence: "uous", value: 3},
    {sequence: "ness", value: 4},
    {sequence: "nesses", value: 2},
    {sequence: "less", value: 3},
    {sequence: "able", value: 2},
    {sequence: "un", value: 3},
    {sequence: "non", value: 2},
    {sequence: "cyan", value: 1},
    {sequence: "eye", value: 2},
    {sequence: "man", value: 1},
    {sequence: "men", value: 1},
    {sequence: "woman", value: 1},
    {sequence: "women", value: 2},
    {sequence: "xy", value: 4},
    {sequence: "trix", value: 3},
    {sequence: "tor", value: 2},
    {sequence: "be", value: 1},
    {sequence: "sus", value: 2},
    {sequence: "fox", value: 10}
];
